<template>
  <div class="article-controllers">
    <div v-if="prevUrl" class="article-controllers-wrap">
      <router-link
        v-if="prevRedirect === false"
        :to="prevUrl"
        class="article-controllers__item article-controllers__item--prev"
      >
        <div class="article-controllers__arrow"></div>

        <span class="article-controllers__title">
          {{ prevArticle }}
        </span>
        <span class="article-controllers__theme">
          {{ prevTheme }}
        </span>
      </router-link>

      <a
        v-if="prevRedirect"
        class="article-controllers__item article-controllers__item--prev"
        :href="`${prevUrl}`"
        target="_blank"
      >
        <div class="article-controllers__arrow"></div>

        <span class="article-controllers__title">
          {{ prevArticle }}
        </span>
        <span class="article-controllers__theme">
          {{ prevTheme }}
        </span>
      </a>
    </div>

    <div v-if="nextUrl" class="article-controllers-wrap article-controllers-wrap__next">
      <router-link
        v-if="nextRedirect === false"
        :to="nextUrl"
        class="article-controllers__item article-controllers__item--next"
      >
        <div class="article-controllers__arrow"></div>

        <span class="article-controllers__title">
          {{ nextArticle }}
        </span>
        <span class="article-controllers__theme">
          {{ nextTheme }}
        </span>
      </router-link>

      <a
        v-if="nextRedirect"
        class="article-controllers__item article-controllers__item--next"
        :href="`${nextUrl}`"
        target="_blank"
      >
        <div class="article-controllers__arrow"></div>

        <span class="article-controllers__title">
          {{ nextArticle }}
        </span>
        <span class="article-controllers__theme">
          {{ nextTheme }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppArticleControllers",
  props: {
    navData: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    prevUrl: "",
    prevArticle: "",
    prevTheme: "",
    prevRedirect: '',
    nextUrl: "",
    nextArticle: "",
    nextTheme: "",
    nextRedirect: '',
  }),
  watch: {
    navData: function () {
      if (this.navData) {
        this.getData();
      }
    },
    $route(from, to) {
      if (from.path !== to.path) {
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      this.currentRoute = this.$route.path;
      let articleIndex;

      if (this.navData && this.navData.length) {
        for (
          let themeIndex = 0;
          themeIndex < this.navData.length;
          themeIndex++
        ) {
          const articles = this.navData[themeIndex].articles;

          for (let i = 0; i < articles.length; i++) {
            if (articles[i].urlPath === this.$route.params.title) {
              articleIndex = i;
              break;
            }
          }

          if (articleIndex >= 0) {
            this.getPrev(themeIndex, articleIndex);
            this.getNext(themeIndex, articleIndex);
            break;
          }
        }
      }
    },
    getPrev(themeIndex, articleIndex) {
      if (articleIndex === 0 && themeIndex !== 0) {
        themeIndex = themeIndex - 1;
        const element =
          this.navData[themeIndex].articles[
            this.navData[themeIndex].articles.length - 1
          ];
        this.prevTheme = this.navData[themeIndex].name;
        this.prevArticle = element.name;
        this.prevUrl = element.urlPath;
        this.prevRedirect = element.redirect;
      } else if (articleIndex > 0) {
        const element = this.navData[themeIndex].articles[articleIndex - 1];
        this.prevTheme = this.navData[themeIndex].name;
        this.prevArticle = element.name;
        this.prevUrl = element.urlPath;
        this.prevRedirect = element.redirect;
      } else {
        this.prevUrl = "";
      }
    },
    getNext(themeIndex, articleIndex) {
      if (themeIndex !== this.navData.length - 1) {
        if (articleIndex !== this.navData[themeIndex].articles.length - 1) {
          const element = this.navData[themeIndex].articles[articleIndex + 1];
          this.nextTheme = this.navData[themeIndex].name;
          this.nextArticle = element.name;
          this.nextUrl = element.urlPath;
          this.nextRedirect = element.redirect;
        } else {
          themeIndex = themeIndex + 1;
          const element = this.navData[themeIndex].articles[0];
          this.nextTheme = this.navData[themeIndex].name;
          this.nextArticle = element.name;
          this.nextUrl = element.urlPath;
          this.nextRedirect = element.redirect;
        }
      } else {
        if (articleIndex !== this.navData[themeIndex].articles.length - 1) {
          const element = this.navData[themeIndex].articles[articleIndex + 1];
          this.nextTheme = this.navData[themeIndex].name;
          this.nextArticle = element.name;
          this.nextUrl = element.urlPath;
          this.nextRedirect = element.redirect;
        } else {
          this.nextUrl = "";
        }
      }
    },
  },
};
</script>

<style lang="scss">
.article-controllers {
  margin-top: 72px;
  margin-bottom: 52px;
  display: flex;
  padding-left: 48px;
  padding-right: 48px;
  max-width: 1082px;

	@media (min-width: 1024px) {
		max-width: 712px;
	}

	@media (min-width: 1280px) {
		max-width: 800px;
	}

	@media (min-width: 1440px) {
		max-width: 836px;
	}

  @media (max-width: 1023px) {
    max-width: initial;
    padding-right: 0;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
  }
}

.article-controllers-wrap {
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);

  &:first-child {
    margin-right: 24px;
  }

  @media (min-width: 768px) {
    width: calc(50% - 24px);

    &:first-child {
      margin-right: 48px;
    }
  }
}

.article-controllers-wrap__next {
  align-items: flex-end;
  margin-left: auto;
}

.article-controllers__item {
  display: flex;
  flex-direction: column;
  letter-spacing: -0.023em;
  flex-grow: 1;
  width: 100%;


  //img {
  //  opacity: 0.1;
  //  margin-bottom: 24px;
  //  transition: opacity 300ms ease-out;
  //}

  &:before {
    content: none !important;
  }
}

.article-controllers__item:hover {
  //img {
  //  opacity: 1;
  //}

  .article-controllers__arrow {
    opacity: 1;
  }

  .article-controllers__title {
    color: rgba(0, 0, 0, 1);
  }

  .article-controllers__theme {
    color: rgba(0, 0, 0, 0.7);
  }
}

.article-controllers__item--prev {
  margin-right: auto;
}

.article-controllers__item--next {
  margin-left: auto;
  align-items: flex-end;

  .article-controllers__title {
    text-align: right;
  }
}

.article-controllers__title {
  font-size: 16px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 4px;
  transition: opacity 300ms ease-out;
}

.article-controllers__theme {
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  transition: opacity 300ms ease-out;
}

.article-controllers__arrow {
  height: 1px;
  background-color: #000000;
  width: 100%;
  opacity: 0.1;
  margin-bottom: 24px;
  transition: opacity 300ms ease-out;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    transform: rotate(45deg) translate(-2.15px, -4.15px);
  }
}

.article-controllers__item--next .article-controllers__arrow::before {
  right: 0;
  // transform: rotate(-135deg) translate(1px, 2px);
  transform: rotate(-135deg) translate(4.15px, 2.15px);
}
</style>
