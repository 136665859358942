<template>
  <div class="article" :class="{'pubish_date-hidden': !publishDate}">
    <Transition>
      <div v-if="showedImage !== ''" @click="closeImage()" class="image_modal">
        <img :src="showedImage">
        <button @click="closeImage()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.43164 7.05652L17.5686 18.1935L6.43164 7.05652ZM16.7731 6.26102C17.2124 5.82168 17.9247 5.82168 18.3641 6.26102C18.8034 6.70036 18.8034 7.41267 18.3641 7.85201L13.5906 12.6245L18.3641 17.398C18.8034 17.8373 18.8034 18.5496 18.3641 18.9889C17.9247 19.4283 17.2124 19.4283 16.7731 18.9889L11.9996 14.2155L7.22714 18.9889C6.81918 19.3969 6.17585 19.426 5.73427 19.0764L5.63615 18.9889C5.19681 18.5496 5.19681 17.8373 5.63615 17.398L10.4086 12.6245L5.63615 7.85201C5.19681 7.41267 5.19681 6.70036 5.63615 6.26102C6.07549 5.82168 6.7878 5.82168 7.22714 6.26102L11.9996 11.0335L16.7731 6.26102Z" fill="#010712"/>
          </svg>
        </button>
      </div>
    </Transition>
    <div class="article__content" :class="{'paragraphNums': paragraphNums}" v-html="HTMLcontent"></div>
    <app-article-controllers :nav-data="navData"></app-article-controllers>

    <div v-if="rightMenu" class="article__submenu submenu">
      <div class="submenu__element"
           v-for="el of links"
           :key="el.index"
           :data-header="el.link"
           @click="goto(el.link)"
           :class="{'submenu__element--active': el.active}"
      >
        {{ el.text }}
      </div>
    </div>
    <Footers/>
  </div>
  
</template>

<script>
import StaticService from "../services/static";
import AppArticleControllers from "../components/article-controlles";
import Footers from "../components/Footer";

export default {
  name: 'Article',
  components: {
    AppArticleControllers, Footers
  },
  metaInfo() {
    const meta = this.meta;
    return {
      title: `${meta.title}`,
      titleTemplate: '%s · NOVA',
      // meta: [
      //   {
      //     vmid: 'description',
      //     name: 'description',
      //     content: `${meta.description}`,
      //   },
      // ],
    }
  },
  data: () => ({
    HTMLcontent: null,
    meta: {
      title: '',
      description: ''
    },
    links: [],
    showedImage: '',
    scrolling: false,
    scrollTimeout: 300,
    interval: null,
    rightMenu: window.params.rightMenu,
    paragraphNums: window.params.paragraphNums,
    publishDate: window.params.publishDate,
  }),
  beforeRouteUpdate(to, from, next) {
    if (from.path !== to.path) {
      next();
      scrollTo(0, 0);
      this.getList();
    }
  },
  created() {
    this.staticService = new StaticService();
    this.getStructure();
  },
  computed: {
    navData() {
      return this.$store.getters.getNav;
    }
  },
  watch: {
    navData() {
      this.getData();
    }
  },
  mounted() {
    this.headerHeight = document.querySelector('.header').offsetHeight;


    const event = this.openImage;
    const article = document.querySelector('.article');

    article.addEventListener('click', function (e) {
      if (e.target.nodeName === 'IMG') {
        const src = e.target.getAttribute('src');
        event(src);
      }
    });

    document.addEventListener("keydown", (e) => {
      if (e.code == "Escape") this.closeImage();
    });
  },
  methods: {
    openImage(src) {
      this.showedImage = src;
      document.body.style.overflowY = "hidden";
    },
    closeImage() {
      this.showedImage = '';
      document.body.style.overflowY = "auto";
    },
    getStructure() {
      this.staticService.getList()
          .then((res) => {
            this.$store.commit('setData', res.data);
          })
    },
    getList() {
      if (!this.navData) {
        this.staticService.getList()
            .then((res) => {
              this.$store.commit('setData', res.data);
              this.getData();
            })
      } else {
        this.getData();
      }
    },
    getData() {
      let fileName = '';
      let currentTheme;
      let currentArticle;

      for (let i = 0; i < this.navData.length; i++) {
        const articles = this.navData[i].articles;

        currentTheme = i;

        for (let y = 0; y < articles.length; y++) {
          if (articles[y].urlPath === this.$route.params.title) {
            fileName = articles[y].fileName;
            this.meta = articles[y].meta;
            currentArticle = y;
            break;
          }
        }

        if (fileName) break;
      }

      let val = this.navData[currentTheme]?.articles[currentArticle]?.value;

      if (!val) {
        const src = `${fileName}.html`;

        this.staticService.getArticle(src)
            .then(res => {
              this.navData[currentTheme].articles[currentArticle]['value'] = res.data;
              this.setData(res.data);

              this.uploadAnother();
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.setData(val);
      }
    },
    uploadAnother() {
      this.navData.forEach((item, index) => {
        item.articles.forEach((aItem, aIndex) => {
          if (!aItem.value) {
            this.staticService.getArticle(aItem.fileName + '.html')
                .then(res => {
                  this.navData[index].articles[aIndex]['value'] = res.data;
                })
                .catch(err => {
                  console.log(err)
                })
          }
        })
      })
    },
    setData(value) {
      this.HTMLcontent = value;

      setTimeout(() => {
        this.getLinks();
        this.scrollTo();

        this.addLinksWatcher();
      }, 100);
    },
    setActive(el) {
      if (el) {
        this.links.forEach(item => item.active = false);
        el.active = true;
      }
    },
    goto(forElement) {
      const element = document.querySelector(`[data-link="${forElement}"]`);

      if (element) {
        // this.setActive(forElement);
        clearInterval(this.interval);

        window.scrollTo({
          top: element.offsetTop - this.headerHeight,
          behavior: 'smooth'
        });

        // set hash
        window.location.hash = '#' + forElement;

        setTimeout(() => {
          this.setScrollingInterval();
        }, 500);
      }
    },
    scrollTo() {
      if (window.location.hash) {
        const x = document.querySelector(`[href="${window.location.hash}"]`);
        if (x) {
          window.scrollTo({
            top: x.offsetTop - this.headerHeight,
            behavior: 'smooth'
          })
        }
      }
    },
    getLinks() {
      this.links = [];
      const elements = document.querySelectorAll('[data-link]');

      Array.from(elements).forEach(el => {
        el.addEventListener('click', (event) => {
          if (window.innerWidth > 767) {
            event.preventDefault();
            this.goto(el.dataset.link);
          }
        })

        el.href = '#' + el.dataset.link;
        this.links.push({
          text: el.innerText.trim(),
          link: el.dataset.link,
          active: false
        })
      })
    },
    isElementVisible(el) {
      const rect = el.getBoundingClientRect(),
          vWidth = window.innerWidth || document.documentElement.clientWidth,
          vHeight = window.innerHeight || document.documentElement.clientHeight,
          efp = function (x, y) {
            return document.elementFromPoint(x, y);
          };

      if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) return false;

      return (
          el.contains(efp(rect.left, rect.top)) ||
          el.contains(efp(rect.right, rect.top)) ||
          el.contains(efp(rect.right, rect.bottom)) ||
          el.contains(efp(rect.left, rect.bottom))
      );
    },
    detectVisibility() {
      const elements = Array.from(document.querySelectorAll('[data-link]'));

      for (let i = 0; i < elements.length; i++) {
        if (this.isElementVisible(elements[i])) {
          const linkItem = this.links.find(item => item.link === elements[i].dataset.link);
          this.setActive(linkItem);
          break;
        }
      }
    },
    setScrollingInterval() {
      this.interval = setInterval(() => {
        if (this.scrolling) {
          this.scrolling = false;
          this.detectVisibility();
        }
      }, this.scrollTimeout);
    },
    addLinksWatcher() {
      this.detectVisibility();

      this.setScrollingInterval();

      window.addEventListener(
          'scroll',
          () => {
            this.scrolling = true;
          },
          {passive: true}
      );
    }
  }
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
}

.image_modal {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 1023px) {
  .image_modal {
    padding: 20px;
  }
}

.image_modal img {
  max-width: 1200px;
  /* max-height: 720px; */
  width: 100%;
  height: auto;
  cursor: default;
}

.image_modal img:hover {
  opacity: 1;
}

.image_modal button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .2s ease-out;
  background-color: rgba(255,255,255,1);
  backdrop-filter: blur(50px);
}

.image_modal button:hover {
  background-color: rgba(255,255,255,.64);
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.article {
  position: relative;
  width: 100%;
  max-width: 1082px;
}

.article__content {
  width: 100%;
}

@media (min-width: 768px) {
  .article__content {
    padding-left: 48px;
  }
}

@media (min-width: 1024px) {
  .article__content {
    max-width: 712px;
    padding-left: 48px;
    padding-right: 48px;
  }
}

@media (min-width: 1280px) {
  .article__content {
    max-width: 800px;
  }
}

@media (min-width: 1440px) {
  .article__content {
    max-width: 836px;
  }
}

@media (max-width: 767px) {
  .article__content {
    max-width: 100%;
    padding-left: 0;
  }
}

>>> .pubish_date {
  display: block;
  margin-bottom: 48px;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  >>> .pubish_date {
    font-size: 14px;
    margin-bottom: 32px;
  }
}

>>> .link_large {
  margin-top: 12px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

>>> .link_large__icon {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  border-radius: 50%;
  /* margin-top: 2px; */
  background-color: #F3F3F3;
  transition: 300ms ease-out;
  left: 0;
  bottom: 0;
}

>>> .link_large__icon svg path {
  fill: #8E8E8E;
  transition: 300ms ease-out;
}

>>> a.link_large:hover .link_large__icon {
  background-color: #000000;
}

>>> a.link_large:hover .link_large__icon svg path {
  fill: #FFFFFF;
}

>>> .link_large__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 44px;
    width: calc(100% - 60px);
}

>>> .link_large__text span {
  display: block;
  letter-spacing: -0.023em;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  transition: color 300ms ease-out;
}

>>> .link_large__text span:first-child {
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 3px;
}

>>> .link_large__text span:last-child {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 3px;
}

>>> .link_large::before {
  content: none;
}

>>> a.link_large:hover .link_large__text span:first-child {
  color: #000000;
}

>>> a.link_large:hover .link_large__text span:last-child {
  color: rgba(0, 0, 0, 1);
}

>>> .figma_path {
  margin-top: 12px;
  margin-bottom: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

>>> .figma_path__icon_wrapper {
  display: flex;
  align-items: flex-start;
}

>>> .figma_path__text_wrapper {
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
}

>>> .figma_path__icon {
  display: block;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  border-radius: 50%;
  /* margin-top: 2px; */
  background-color: #F3F3F3;
  transition: 300ms ease-out;
  left: 0;
  bottom: 0;
}

>>> .figma_path__icon svg path {
  fill: #8E8E8E;
  transition: 300ms ease-out;
}

>>> .figma_path__text {
  display: block;
  width: 100%;
  line-height: 24px;
  margin-top: -2px;
}

>>> .teaser {
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  font-family: "SB-Sans-display-light";
}

@media (min-width: 768px) {
  >>> .teaser {
    font-size: 24px;
    line-height: 34px;
  }
}

>>> a {
  color: inherit;
  text-decoration: none;
  position: relative;
}

>>> p a {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
    /*color: #8E8E8E;*/
    color: #0052CB;
    transition: color .2s;
}

>>> li a {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
    /*color: #8E8E8E;*/
    color: #0052CB;
    transition: color .2s;
}

>>> li a:hover {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0); */
    /*color: #000000;*/
    color: #4D95FF;
}

>>> p a:hover {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0); */
    /*color: #000000;*/
    color: #4D95FF;
}

>>> p a::before {
  display: none;
}

>>> li a::before {
  display: none;
}

@media (min-width: 768px) {
  >>> a::before {
    content: '';
    position: absolute;
    width: 48px;
    height: 100%;
    transform: translateX(-100%);
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./assets/link.svg");
    opacity: 0;
    transition: 300ms ease-out;
  }

  >>> a:hover::before {
    opacity: 1;
  }
}

>>> h1 {
  margin-top: 0;
  color: #000000;
  font-size: 56px;
  line-height: 80px;
  margin-bottom: 56px;
  letter-spacing: -0.025rem;
  font-family: "SB-Sans-display";
}

@media (max-width: 767px) {
  >>> h1 {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 32px;
  }
}

>>> p {
  color: #000000;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.023em;
  margin-bottom: 16px;
}

>>> h2 {
  margin-top: 0;
  color: #000000;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
  font-family: "SB-Sans-display";
}

@media (max-width: 767px) {
  >>> h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
  }
}

>>> h3 {
  margin-top: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

>>> div img {
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    /* border-radius: 14px; */
}

>>> img {
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 100%;
  height: auto;
  font-size: 0;
  line-height: 0;
  transition: opacity .2s ease-out;
  cursor: pointer;
}

>>> img:hover {
  opacity: .88;
}

>>> img + span {
  display: block;
  padding-bottom: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.023em;
}

@media (max-width: 767px) {
  >>> img {
    margin-bottom: 4px;
  }
}

>>> ul,
>>> ol {
  margin-top: 0;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  >>> ul,
  >>> ol {
    padding-left: 28px;
  }
}

>>> ul li,
>>> ol li {
  color: #000000;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.023em;
}

>>> blockquote {
  margin: 0;
  padding: 0;
  padding-left: 44px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.023em;
  color: rgba(0, 0, 0, 0.5);
  border-left: 4px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  >>> blockquote {
    padding-left: 24px;
  }
}

>>> hr {
  margin-top: 72px;
  margin-bottom: 72px;
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  >>> hr {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

>>> blockquote + p img {
  margin-top: 16px;
}

>>> p + p img {
  margin-top: 12px;
}

@media (max-width: 767px) {
  >>> p + p img {
    margin-top: 4px;
  }
}

>>> code {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.023em;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  padding: 0 5px;
  font-family: inherit;
}

>>> p + h2,
>>> ul + h2,
>>> div + h2,
>>> p + h3,
>>> ul + h3,
>>> div + h3,
>>> blockquote + h2,
>>> blockquote + h3,
/* >>> div + p, */
/* >>> .article__content div + div, */
>>> .link_large + h2,
>>> .link_large + h3,
>>> .link_large + p {
    margin-top: 60px;
}

>>> div + ul,
>>> div + p {
  margin-top: 28px;
}

>>> ul + div img {
  margin-top: -4px;
}

>>> p + div img {
  margin-top: 4px;
}


>>> p + .link_large,
>>> div + .link_large {
    margin-top: 24px;
}

>>> .figma_path + h2,
>>> .figma_path + h3 {
    margin-top: 60px;
}

>>> .figma_path + p {
    margin-top: 40px;
}

>>> p + .figma_path,
>>> div + .figma_path {
    margin-top: 24px;
}

@media (max-width: 767px) {
    >>> p + h2,
    >>> ul + h2,
    >>> div + h2,
    >>> p + h3,
    >>> ul + h3,
    >>> div + h3,
    >>> div + ul,
    >>> blockquote + h2,
    >>> blockquote + h3,
    /* >>> div + p, */
    /* >>> .article__content div + div, */
    >>> .link_large + h2,
    >>> .link_large + h3,
    >>> .link_large + p {
        margin-top: 44px;
    }

    >>> p + .link_large,
    >>> div + .link_large {
        margin-top: 20px;
    }

    >>> .figma_path + h2,
    >>> .figma_path + h3,
    >>> .figma_path + p {
        margin-top: 44px;
    }

    >>> p + .figma_path,
    >>> div + .figma_path {
        margin-top: 20px;
    }
}

.pubish_date-hidden >>> .pubish_date {
  display: none;
}

@media (min-width: 768px) {

  .article__content.paragraphNums >>> {
    counter-reset: p;
  }

  .article__content.paragraphNums >>> > p {
    position: relative;
  }

  .article__content.paragraphNums >>> > p:before {
    content: counter(p);
    counter-increment: p;
    position: absolute;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.24);
    left: 0;
    transform: translateX(-100%);
    font-size: 11px;
    line-height: 14px;
    height: 28px;
  }
}

.article__submenu {
  width: 146px;
  top: 112px;
  right: 18%;
  margin-right: -146px;
  position: fixed;
}

@media (max-width: 1023px) {
  .submenu {
    display: none;
  }
}

.submenu__element {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.023em;
  color: #8E8E8E;
  padding: 12px 0 12px 20px;
  position: relative;
  cursor: pointer;
  transition: color 300ms ease-out;
}

.submenu__element::before {
  content: '';
  position: absolute;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  transition: background-color 300ms ease-out;
}

.submenu__element:hover {
  color: #000000;
}

.submenu__element:hover::before {
  background-color: rgba(0, 0, 0, 0.4);
}

.submenu__element--active {
  color: #000000;
}

.submenu__element--active:hover::before,
.submenu__element--active::before {
  background: #000000;
}
</style>
