<template>
	<footer class="footer">
		<a
			href="https://confluence.sberbank.ru/display/DS/Playgrounds"
			class="footer__link"
			target="_blank"
		>
			Playgrounds App
		</a>
		<a
			href="https://web.dsgnsystem.ru/#/develop"
			class="footer__link"
			target="_blank"
		>
			Playground Web
		</a>
		<a
			href="https://confluence.sberbank.ru/pages/viewpage.action?pageId=879079086"
			class="footer__link"
			target="_blank"
		>
			Кухня
		</a>
		<a
			href="https://confluence.sberbank.ru/pages/viewpage.action?pageId=1611995904"
			class="footer__link"
			target="_blank"
		>
			Задать вопрос
		</a>
		<!-- <a 
			href="https://web.dsgnsystem.ru/"
			class="footer__button"
			target="_blank"
		>
			Web Guides
		</a> -->
	</footer>
</template>

<script>
	export default {
		name: "Footer",
		mounted() {
			// this.isMobile = window.innerWidth < 1025;
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

* {
	box-sizing: border-box;
}

.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 1082px;
	padding: 100px 16px 40px;

	@media (min-width: 1024px) {
		max-width: 712px;
	}

	@media (min-width: 1280px) {
		max-width: 800px;
	}

	@media (min-width: 1440px) {
		max-width: 836px;
	}

	@media (max-width: 767px) {
		flex-direction: column;
        padding: 0 16px 40px;
	}
}

.footer__link {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	letter-spacing: -0.023em;
	text-decoration: none;
	color: #000000;
	opacity: .5;
	padding: 0 32px;
	transition: opacity .2s ease-out;

	@media (max-width: 767px) {
		padding: 22px 0;
	}

	&:hover {
		opacity: 1;
	}

	&::before {
		display: none;
	}

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}

.footer__button {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	letter-spacing: -0.023em;
	text-decoration: none;
	color: #000000;
	padding: 8px 16px;
	margin-left: 16px;
	border-radius: 6px;
	border: 1px solid rgba(0,0,0,.1);
	transition: border .2s ease-out;

	@media (max-width: 767px) {
		margin-left: 0;
		margin-top: 22px;
	}

	&:hover {
		border: 1px solid rgba(0,0,0,.5);
	}
}
</style>
